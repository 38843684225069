import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/default-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`About NWRS`}</h1>
    <p>{`To most people, buying a property is the biggest investment they have ever made. It makes sense to take care of the fabric that holds it together, keeping it weatherproof against the ravages of the British weather.`}</p>
    <p>{`Whatever the type, condition or location of your property, we have the experience and expertise to renovate and decorate the exterior of your property. Using our specially selected coating systems, we can provide protection for even the most exposed properties that are constantly being battered by the elements.`}</p>
    <h2>{`Our Service Standards`}</h2>
    <p>{`As rendering contractors we believe that it is paramount to offer the very best quality and value for money for our customers, whilst providing an innovative and efficient service.`}</p>
    <p>{`Our service standards are outlined below:`}</p>
    <ul>
      <li parentName="ul">{`10 Year Guarantee against lifting, cracking and water penetration`}</li>
      <li parentName="ul">{`No subcontractors or salesmen`}</li>
      <li parentName="ul">{`Realistic and competitive quotations`}</li>
      <li parentName="ul">{`100% commitment to the work we carry out`}</li>
      <li parentName="ul">{`Full public liability insurance`}</li>
      <li parentName="ul">{`Registered with Companies House`}</li>
      <li parentName="ul">{`Proven track record since 1990`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      